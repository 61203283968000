.qmpApplicationPage {
    padding-left: 2rem;
    font-weight: 500;
}

.selectContainer {
    width: 50%;
    margin-left: 24px;
    z-index: 2;

    .selectField {
        width: 100%;
        max-width: inherit;
    }
}

.rightAlign {
    justify-content: flex-end;
}

.textField {
    margin-left: 10px;
    height: 45.33px;
    width: 50%;
    input {
        height: 45.33px;
        width: 100%;
    }
}

.formWrapper{
    padding: 1.5rem;
    margin: 2rem auto;
    font-weight: 500;
    max-width: 75%;
    width: 75%;
    font-weight: 500;
    border: 1px solid var(--grey-500);
    border-radius: 5px;
}

@media (max-width: 800px) {
    .qmpApplicationPage {
        padding-left: 0;
    }
    .fieldWrapper{
        flex-direction: column !important;
    }

    .selectContainer {
        width: 100%;
        margin: 10px;

        .selectField {
            width: 100%;
            max-width: inherit;
        }
    }
}
